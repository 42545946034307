/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/components/auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function NoEvents() {
    return (
        <div
            style={{
                display: "flex",
                paddingBlock: "0.5em",
                flexDirection: "column",
                fontFamily: "Poppins",
                gap: "1em",
                alignItems: "center",
            }}
        >
            <svg
                width="194"
                height="187"
                viewBox="0 0 194 187"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M101.751 17.2846C101.751 17.2846 106.785 49.6564 161.049 50.5499C209.24 51.3433 193.924 108.662 176.818 126.685C154.658 150.032 111.231 143.925 108.951 171.1C106.672 198.276 45.4489 187.725 46.4917 146.752C47.7889 95.7828 19.8704 111.281 5.73667 97.6476C-4.40717 87.8626 -0.920469 42.5679 31.2319 37.3823C58.2875 33.0188 56.8848 21.5559 59.0359 13.0699C62.1379 0.83141 93.5027 -10.2549 101.751 17.2846Z"
                    fill="#DEE9FF"
                />
                <path
                    d="M152.799 132.517C152.802 133.34 152.643 134.155 152.33 134.915C152.017 135.676 151.558 136.368 150.977 136.95C150.397 137.533 149.707 137.995 148.947 138.311C148.188 138.626 147.373 138.789 146.551 138.789L46.3262 138.993C45.5039 138.995 44.6893 138.835 43.9289 138.522C43.1685 138.209 42.4773 137.749 41.8948 137.169C41.3123 136.589 40.8499 135.899 40.534 135.14C40.2182 134.381 40.0551 133.567 40.0542 132.744L39.9133 62.2737L95.3894 27.4455C95.6376 27.2889 95.9248 27.2051 96.2183 27.2037C96.5118 27.2023 96.7997 27.2834 97.0494 27.4376L151.359 61.2398L152.659 62.0463V63.1739L152.799 132.517Z"
                    fill="#208AF2"
                />
                <path
                    d="M144.622 33.5109H47.3535C47.1216 33.5109 46.9335 33.699 46.9335 33.9309V138.014C46.9335 138.246 47.1216 138.434 47.3535 138.434H144.622C144.854 138.434 145.042 138.246 145.042 138.014V33.9309C145.042 33.699 144.854 33.5109 144.622 33.5109Z"
                    fill="#699CFF"
                />
                <path
                    d="M146.451 138.69L96.7044 100.76C96.6643 100.73 96.6321 100.69 96.6103 100.644C96.5886 100.599 96.5779 100.549 96.5792 100.499C96.5805 100.448 96.5937 100.399 96.6178 100.355C96.6419 100.311 96.6761 100.273 96.7177 100.244L152.158 62.3522C152.205 62.3196 152.261 62.3006 152.319 62.2973C152.377 62.294 152.434 62.3065 152.486 62.3334C152.537 62.3604 152.58 62.4008 152.61 62.4503C152.64 62.4998 152.655 62.5565 152.656 62.6144L152.796 132.483C152.799 134.125 152.158 135.702 151.01 136.875C149.863 138.049 148.301 138.725 146.66 138.759C146.584 138.76 146.511 138.736 146.451 138.69Z"
                    fill="#A2BDFF"
                />
                <path
                    d="M46.4104 138.889L96.0048 100.762C96.0448 100.731 96.0768 100.691 96.0984 100.646C96.12 100.6 96.1305 100.55 96.129 100.5C96.1275 100.449 96.114 100.4 96.0898 100.356C96.0655 100.312 96.0311 100.274 95.9894 100.246L40.3991 62.5755C40.3512 62.5431 40.2953 62.5243 40.2376 62.5212C40.1798 62.5181 40.1223 62.5309 40.0712 62.558C40.0201 62.5852 39.9774 62.6257 39.9476 62.6753C39.9178 62.7249 39.9021 62.7817 39.9022 62.8396L40.0422 132.709C40.0455 134.35 40.6927 135.924 41.8447 137.093C42.9966 138.262 44.5613 138.932 46.2022 138.959C46.2775 138.96 46.3508 138.935 46.4104 138.889Z"
                    fill="#A2BDFF"
                />
                <path
                    d="M146.543 138.76L46.3186 138.96C44.8024 138.959 43.3378 138.409 42.1953 137.413C41.0528 136.416 40.3095 135.039 40.1026 133.537C40.0685 133.281 40.0981 133.021 40.1888 132.779C40.2795 132.537 40.4285 132.321 40.6225 132.15L95.2855 83.7895C95.571 83.5369 95.9388 83.3971 96.32 83.3963C96.7012 83.3956 97.0696 83.5339 97.3561 83.7853L152.211 131.927C152.406 132.097 152.556 132.312 152.648 132.554C152.739 132.795 152.77 133.056 152.737 133.312C152.536 134.815 151.798 136.194 150.66 137.195C149.522 138.197 148.059 138.752 146.543 138.76Z"
                    fill="#418DF9"
                />
                <path
                    d="M71.831 59.3503C73.777 59.3503 75.3545 57.2469 75.3545 54.6523C75.3545 52.0577 73.777 49.9543 71.831 49.9543C69.885 49.9543 68.3075 52.0577 68.3075 54.6523C68.3075 57.2469 69.885 59.3503 71.831 59.3503Z"
                    fill="white"
                />
                <path
                    d="M121.161 59.3503C123.107 59.3503 124.684 57.2469 124.684 54.6523C124.684 52.0577 123.107 49.9543 121.161 49.9543C119.215 49.9543 117.637 52.0577 117.637 54.6523C117.637 57.2469 119.215 59.3503 121.161 59.3503Z"
                    fill="white"
                />
                <path
                    d="M109.808 74.9277C109.689 74.9278 109.572 74.8976 109.468 74.84C109.364 74.7825 109.276 74.6993 109.213 74.5985C109.177 74.5401 105.345 68.6205 96.438 67.8785C92.7309 67.5698 89.2129 68.602 85.9857 70.9477C84.5464 71.9812 83.2679 73.222 82.1917 74.6297C82.0847 74.7804 81.9223 74.8827 81.7401 74.9141C81.558 74.9456 81.3707 74.9037 81.2193 74.7976C81.068 74.6915 80.9647 74.5298 80.9321 74.3478C80.8994 74.1658 80.9401 73.9784 81.0453 73.8263C81.1039 73.7423 82.5122 71.7516 85.1208 69.8454C88.5885 67.3114 92.5424 66.1494 96.5541 66.483C106.189 67.2859 110.233 73.5888 110.4 73.8567C110.466 73.9626 110.503 74.0843 110.506 74.2091C110.51 74.334 110.479 74.4575 110.419 74.5668C110.358 74.6761 110.27 74.7672 110.162 74.8307C110.055 74.8942 109.932 74.9277 109.807 74.9278L109.808 74.9277Z"
                    fill="white"
                />
            </svg>

            <p
                style={{
                    marginTop: "1em",
                    fontSize: "1.25em",
                    lineHeight: "1.75em",
                    fontWeight: 700,
                    margin: 0,
                }}
            >
                Schlechte Nachrichten
            </p>
            <p style={{ textAlign: "center", maxWidth: "45ch", margin: 0 }}>
                Leider gibt es keine Events mit diesen Kriterien
            </p>
        </div>
    )
}
